import React from 'react'
import { StyleSheet, css } from 'aphrodite';
import bg from '../static/bg-pattern.png'
import { Row, Col, Typography } from 'antd';
import r1 from '../static/r1.png'
import r2 from '../static/r2-1.png'
import r3 from '../static//r3-1.png'
import r4 from '../static/r4-1.png'
import r5 from '../static/r5-1.png'
import r6 from '../static/r6-1.png'
import joker from '../static/joker.png'

import Center from '../LandingPage/Componenets/Center';
let styles = StyleSheet.create({
    background: {
        background: `url(${bg}), linear-gradient(to left, #7b4397, #dc2430)`,
        color: 'white'
    }
})
const Rules = () => {
    let listOfTips = (list: any) => {
        return <Row gutter={[0, { xs: 8, sm: 16, md: 24, lg: 32 }]} style={{ marginBottom: '0px' }}>
            <Col offset={4} >
                <ul>
                    {list.map((item: any, index: any) => {
                        return <li key={index}>
                            <Typography.Text style={{ color: 'white' }}>
                                {item}
                            </Typography.Text>
                        </li>
                    })}
                </ul>

            </Col>
        </Row>
    }
    let section = (title: string) => {
        return <Row gutter={[0, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col offset={3} >
                <Typography.Title level={3} style={{ color: 'white' }}>
                    {title}
                </Typography.Title>
            </Col>
        </Row>
    }
    let img = (img: any) => {
        return <Center>
            <div>
                <img src={img} alt="" style={{
                    float: 'right',
                    height: 'auto',
                    width: 'min(350px,60vw)',
                }} />

            </div>
        </Center>
    }
    return (
        <div className={css(styles.background)}>
            <h1 style={{ color: 'white', padding: '2% 0 0 2%', fontSize: '2vw' }}>Game Rules</h1>
            {section('Each turn is divided into three phases:')}
            {listOfTips(['Draw a card from the deck or from the discard pile.',
                'Combine cards to make new sets or add cards at already existing ones (optional).',
                'Discarding cards from your hand to the pile, which ends the turn.'])}
            {section('Drawing cards from the Deck or the discard pile:')}
            {listOfTips(['The player has the option to draw from the deck or the discard pile.',
                'In the case of discard pile, the player must pick up all the cards in the pile.',
                ' At the beginning of the game the discard pile is empty, so the first player must draw up to two cards from the deck .'])}
            {img(r1)}
            {section('Adding a new set to the board:')}
            {listOfTips([
                'In this optional phase the player can  Put down new groups or sequences(sets).',
                'Attach cards to his/her own groups or sequences.'
            ])}
            {img(r3)}
            {section('200')}
            {listOfTips([
                '"200" is a group or sequences of at least 7 cards called project.'
            ])}
            {section('The "200" project can be:')}
            {listOfTips([
                'Clean, without any joker, except if a joker in a position for it\'s own face value and they called "200" project.',
                'Dirty, contain one joker not in a position for it\'s own face value and they called "100" project.'
            ])}
            {img(r4)}
            {section('Sequence')}
            {listOfTips([
                'Three or more cards of the same suit, ordered in sequence.'
            ])}
            {img(r5)}

            {section('Group')}
            {listOfTips([
                'Three or more cards of the same rank.'
            ])}
            {img(r6)}
            {section('Joker')}
            {listOfTips([
                'The jokers in the "200" is the cards of number two.',
                'There is eight jokers in the game.',
                'If a team completed a set of seven jokers, the team wins the game.'
            ])}
            {img(joker)}
            {section('Hundred not taking')}
            {listOfTips([
                "To avoid a hundred not taking and losing 100 point at least one component of the couple of players should get to the little deck on the right of discard pile(called hundred) by putting down all his/her cards after having at least one set of 7 cards. he/she well be dealt with a complete hand of 11 new cards.", 'There is eight jokers in the game.',
                'If a team completed a set of seven jokers, the team wins the game.'
            ])}
            {img(r2)}
            {section('Other rules')}
            {listOfTips([
                'You can\'t use more than one joker in any sets.',
                'When attaching a card, you can move the joker, but always within the same set.',
                'When you have one card in your hand and there is only one card on the discard pile you can\'t take it, you must take a card from the deck of cards.'
            ])}

        </div>
    )
}

export default Rules
