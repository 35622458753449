import React from 'react';
import { Menu } from 'antd';
import { withRouter } from 'react-router-dom';
const Navbar = withRouter((props: any) => {
	return (
		<Menu onClick={() => {}} selectedKeys={[props.location.pathname]} mode="horizontal">
			{props.menu.map((item: any, index: any) => {
				return (
					<Menu.Item key={item.path}>
						<a href={item.path} key={index}>
							{item.title}
						</a>
					</Menu.Item>
				);
			})}
		</Menu>
	);
});

export default Navbar;
