import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import bg from '../static/bg-pattern.png';
import { Row, Col } from 'antd';
let styles = StyleSheet.create({
	background: {
		background: `url(${bg}), linear-gradient(to left, #7b4397, #dc2430)`,
		color: 'white',
	},
});

const Privacy = () => {
	let title = (title: any) => {
		return (
			<Row
				style={{
					fontSize: 'min(24px,2.5vw)',
				}}
			>
				<Col offset={1}>{title}</Col>
			</Row>
		);
	};
	let subtitle = (title: any) => {
		return (
			<Row
				style={{
					fontSize: 'min(12px,2vw)',
				}}
			>
				<Col offset={1}>{title}</Col>
			</Row>
		);
	};
	let section1 = (title1: any, title2: any, paragraph: any) => {
		return (
			<ul>
				<li>
					{title1}
					<ul>
						<li>
							{title2}
							<ul>
								{paragraph.map((item: any, index: any) => {
									return <li key={index}>{item}</li>;
								})}
							</ul>
						</li>
					</ul>
				</li>
			</ul>
		);
	};
	let section2 = (title1: any, paragraph: any) => {
		return (
			<ul>
				<li>
					{title1}
					<ul>
						<li>{paragraph}</li>
					</ul>
				</li>
			</ul>
		);
	};
	return (
		<div className={css(styles.background)}>
			<Row
				justify="center"
				style={{
					fontSize: 'max(36px,4vw)',
				}}
			>
				<Col offset={1}>Privacy Policy</Col>
			</Row>
			<Row
				justify="center"
				style={{
					fontSize: 'min(12px,2vw)',
				}}
			>
				<Col offset={1}>This app collects some of your users' personal information.</Col>
			</Row>
			<br />
			{title('SUMMARY OF THE POLICY')}
			<Row>
				<Col offset={2}>
					<ol>
						<li>
							Personal data collected for the following reasons and services:
							{section1('Access to third-party accounts', 'Access to Facebook account', [
								'Permissions: Birthday, Contact Email and Home City',
							])}
							{section1('Advertising', 'Admob', ['Personal data: Cookies and Data used'])}
							{section1('Analysis', 'Facebook Ads conversion tracking, Google Analytics and Quantcast Measure', [
								'Personal data: Cookies and Data used',
							])}
							{section1('Contact user', 'Contact Form', ['Personal Information: Email and first name'])}
							{section1('Registration and authentication', 'Facebook Authentication', ['Personal data: Various data'])}
							{section1('Location Service', 'Access location on mobile device', ['Personal data: Various data'])}
						</li>
						<br />
						<li>
							More information about Personal Data
							<ul>
								<li>
									Push notifications
									<ul>
										<li>This app can send push notifications to the user</li>
									</ul>
								</li>
								<li>
									Data Deletion
									<ul>
										<li>
											A user can delete data using delete account from within the application or by contacting us
											through email
										</li>
									</ul>
								</li>
							</ul>
						</li>
						<br />

						<li>
							Contact information
							<ul>
								<li>
									Data Controller
									<ul>
										<li>Contact - Meetain.io@gmail.com</li>
									</ul>
								</li>
							</ul>
						</li>
					</ol>
				</Col>
			</Row>
			{title('INTEGRAL POLICY')}
			{subtitle('Types of data collected:')}
			<Row>
				<Col offset={2}>
					<p>This application collects, by itself or from third parties: Cookies, Data used, First name and Email.</p>
					<p>
						Other personal data collected is described elsewhere in this privacy policy or by a specific text along with the
						collection of data. Personal data may be provided by the user or collected automatically during the use of the
						application. Any use of cookies by this application, by itself or by third parties, serves to identify users and
						remember their preferences, with the purpose of offering the best service to the user. By not providing certain
						data, the application may not provide its services properly.
					</p>
					<p>
						The user assumes responsibility for the data of third parties published or shared by this application and declares
						to have the right to communicate or to spread them, removing from the controller of the data any responsibility.
					</p>
				</Col>
			</Row>
			{title('Data Processing and Location')}
			{subtitle('Processing Methods:')}
			<Row>
				<Col offset={2}>
					<p>
						The data controller processes user data in an appropriate manner, taking the necessary security measures to prevent
						unauthorized access, modification or deletion of data. Processing happens with computers and other IT tools
						following organizational patterns. In addition to the data controller, in some cases the data can be accessed by
						certain people in command, involved in the operation of the site (administration, sales, marketing, legal) or third
						parties (email providers, IT companies, . A list of these third parties may be required from the data controller at
						any time.{' '}
					</p>
				</Col>
			</Row>
			<br />
			{subtitle('Location:')}
			<Row>
				<Col offset={2}>
					<p>
						Data is processed in the operating office of the data controller and anywhere else where involved third parties are
						located. For more information, contact the data controller.
					</p>
				</Col>
			</Row>
			<br />

			{subtitle('Retention time:')}
			<Row>
				<Col offset={2}>
					<p>
						The data is maintained for the time required to provide the service required by the user. The user can always ask
						the controller to suspend or remove their data.
					</p>
				</Col>
			</Row>
			<br />

			{subtitle('Use of collected data:')}
			<Row>
				<Col offset={2}>
					<p>Data is collected for the application to offer its services. They are also used for the following purposes:</p>
				</Col>
			</Row>
			<br />
			{subtitle('Facebook permissions required by the application:')}
			<Row>
				<Col offset={2}>
					<p>
						This application can ask for some Facebook permissions, allowing it to access the user's Facebook for data
						collection.
					</p>
					<p>The requested permissions are as follows:</p>
					{section2(
						'Basic information:',
						'Includes user data such as id, name, photo, gender, and location. Certain connections as friendships are also available. All public user data can be accessed'
					)}
					{section2('About:', 'Releases access to the "on" part of your profile')}
					{section2('Birthday:', 'Report your birthday')}
					{section2('Contact e-mail:', "Access to the user's contact email")}
					{section2('Actual city:', "Access to the current user's city")}
				</Col>
			</Row>
			<br />
			{title('Detailed information on the processing of Personal Data')}
			{subtitle('Personal data is collected for the following reasons:')}
			<Row>
				<Col offset={2}>
					{section1(
						'Access tothird-party service accounts These services allow you to access your account and user data in a third-party service. These services are not activated automatically, but require explicit authorization from the user.',
						'Access to Facebook account',
						[
							"This service allows the application to connect to the user's Facebook",
							'Required Permissions: About, Birthday, Email, and Current City',
						]
					)}
					{section1(
						"Advertising: These services allow the user data to be used for the display of banners and other advertisements in this application, based on the user's interests.",
						'AdMob (AdMob Google Inc)',
						['AdMob is an advertising service from AdMob Google Inc.', 'Personal data collected: Cookies and data used']
					)}
					{section1(
						'Analytics: These services allow the controller to monitor and analyze traffic data to discover user behavior.',
						'Facebook Ads conversion tracking (Facebook)',
						[
							'Facebook Ads conversion tracking is an analysis service from Facebook inc. Which connects Facebook ad network data to the actions of this app.',
							'Personal data collected: Cookies and data used',
						]
					)}
					{section1('Contact with the user', 'Contact form (this application)', [
						'By completing the form with your data, you authorize this application to use these details for information or any other service.',
						'Personal data collected: Email and First name',
					])}
					{section2(
						'Interaction with social networks and external platforms',
						'These services allow interaction with social networks directly by the application. The information obtained is in accordance with the privacy settings of each one in your social network.'
					)}
					{section1(
						'Registration and Authentication:When registering, the user allows the application to identify it. Third-party applications can also access this data to authenticate and identify users',
						'Facebook Authentication',
						['Social network registration and authentication service Facebook.', 'Personal data collected: Miscellaneous data']
					)}
					{section2(
						'Remarketing and behavioral study',
						"These services enable this application to inform, optimize and display advertising according to the user's previous use of the application"
					)}
					<br />
					{title('More information about personal data')}
					{section2('Push notifications', 'This application can transmit push notifications to the user.')}
					<br />
					{title('More information about collecting and processing personal data')}
					{section2(
						'Legal actions',
						'Personal data of the user can be used for legal reasons by the controller of the data. The user becomes aware that the controller may have to disclose personal data if requested by public authorities.'
					)}
					{section2(
						"More information about the user's personal data",
						'In addition to the information contained in this privacy policy, this application can provide more information about other services and the personal data glue.'
					)}
					{section2(
						'System logs and maintenance',
						'For maintenance, this application can collect files that record interactions with the application or other personal data (such as IP address)'
					)}
					<ul>
						<li>
							User rights
							<ul>
								<li>
									Users have the right to know if their personal data have been recorded and can consult the controller to
									verify, update, or even cancel the collection of data for a legitimate reason.
								</li>
								<li>
									This application does not allow the user to deny the collection of your data. To determine if any third
									party service accepts this request, please read its privacy policy
								</li>
							</ul>
						</li>
					</ul>
					{section2(
						'Changes to this policy',
						'The data controller has the right to change this policy at any time by notifying users on this page. It is recommended that the user check this page frequently, based on the date of the last modification at the end of the page. If the user has any objections to the changes, he should stop using the application and can ask the controller to erase all his personal data.'
					)}
					{section2('Information about the privacy policy', 'The data controller is responsible for this privacy policy')}
				</Col>
			</Row>
		</div>
	);
};

export default Privacy;
