import React from 'react'
import { Row, Col } from 'antd'

const MyFooter = () => {
    return (
        <Row justify="center" gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col>
                © Meetain.io All Rights Reserved
            </Col>
            <Col>
                <a href="/privacy">Privacy</a>
            </Col>
            <Col>
                <a href="/aboutus">About us</a>

            </Col>
        </Row>
    )
}

export default MyFooter
