import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import bg from '../static/bg-pattern.png';
import { Row, Col, Typography, Avatar } from 'antd';
import { FacebookFilled } from '@ant-design/icons';

let styles = StyleSheet.create({
	background: {
		background: `url(${bg}), linear-gradient(to left, #7b4397, #dc2430)`,
		color: 'white',
		height: '87.2vh',
	},
});
const Contact = () => {
	return (
		<div className={css(styles.background)}>
			<Row
				justify="center"
				style={{
					paddingTop: '5%',
				}}
			>
				<Col>
					<Typography.Title style={{ color: 'white' }}>Meetain</Typography.Title>
				</Col>
			</Row>
			<Row
				style={{
					paddingTop: '3%',
				}}
			>
				<Col offset={3}>
					<Typography.Title level={3} style={{ color: 'white' }}>
						Meetain
						<b /> is an undergraduate project for computer science department in Kuwait University.
					</Typography.Title>
				</Col>
			</Row>
			<Row
				style={{
					paddingTop: '3%',
				}}
			>
				<Col offset={3}>
					<span style={{ textDecoration: 'underline' }}>
						<Typography.Title level={1} style={{ color: 'white' }}>
							Advisor:
						</Typography.Title>
					</span>
					<Typography.Title level={3}>
						<a href={'https://almohri.io/'}>Dr. Hussain Almohri</a>
					</Typography.Title>
				</Col>
			</Row>
			<Row
				style={{
					paddingTop: '3%',
				}}
			>
				<Col offset={3}>
					<span style={{ textDecoration: 'underline' }}>
						<Typography.Title style={{ color: 'white' }}>Team members:</Typography.Title>
					</span>
					<br />
					<ul>
						<li>
							<Typography.Title level={3} style={{ color: 'white' }}>
								<a href={'https://www.linkedin.com/in/ahmad-al-kandari-76770a143/'}>Ahmad AL-Kandari</a>
							</Typography.Title>
						</li>
						<li>
							<Typography.Title level={3} style={{ color: 'white' }}>
								<a href={'https://www.linkedin.com/in/ahmed-al-qinai-27b760143/'}>Ahmed ALQinai</a>
							</Typography.Title>
						</li>
						<li>
							<Typography.Title level={3} style={{ color: 'white' }}>
								<a href={'https://www.linkedin.com/in/mahmoud-alawadh-178738143'}>Mahmoud ALAwadh</a>
							</Typography.Title>
						</li>
					</ul>
				</Col>
			</Row>
			<Row
				style={{
					paddingTop: '3%',
				}}
			>
				<Col offset={3}>
					<Typography.Title level={4} style={{ color: 'white' }}>
						Email: [game name].io@gmail.com
					</Typography.Title>
					<a href="https://www.facebook.com/meetain.io">
						<Avatar size={'large'} icon={<FacebookFilled />} style={{ color: '#4267B2', backgroundColor: 'white' }} />
					</a>
				</Col>
			</Row>
		</div>
	);
};

export default Contact;
