import React from 'react'
import { Layout, BackTop } from 'antd';
import Navbar from './Navbar';
import MyFooter from './MyFooter';
const { Header, Footer, Content } = Layout;

const MyLayout = (props: any) => {
    return (
        <Layout >
            <Header style={{ backgroundColor: "#fff" }}>
                <Navbar menu={props.menu} />
            </Header>
            <Content>
                <BackTop>
                    <div style={{
                        height: 40,
                        width: 40,
                        lineHeight: '40px',
                        borderRadius: 4,
                        backgroundColor: '#1088e9',
                        color: '#fff',
                        textAlign: 'center',
                        fontSize: 14,
                    }}>UP</div>
                </BackTop>
                {props.children}
            </Content>
            <Footer style={{ background: '#222', color: 'rgba(255, 255, 255, .3)' }}>
                <MyFooter />
            </Footer>
        </Layout>
    )
}

export default MyLayout
