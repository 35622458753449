import React from 'react'
import { Row, Col } from 'antd'

const Center = (props: any) => {
    return (
        <Row justify="center" align={"middle"}>
            <Col>
                {props.children}
            </Col>
        </Row>
    )
}

export default Center
