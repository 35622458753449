import React, { lazy, Suspense } from 'react';
import './App.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Rules from './Components/Rules/Rules';
// import Rank from './Components/Rank/Rank';
// import Tutorial from './Components/Tutorial/Tutorial';
import Contact from './Components/Contact/Contact';
import MyLayout from './Components/Common/MyLayout';
import Privacy from './Components/Privacy/Privacy';
import { LoadingOutlined } from '@ant-design/icons';

import { Row, Col, Spin } from 'antd';
const LandingPage = lazy(() => import('./Components/LandingPage/LandingPage'));
const antIcon = <LoadingOutlined style={{ fontSize: 386 }} spin />;
let paths = [
	{
		path: '/',
		component: LandingPage,
	},
	{
		path: '/rules',
		component: Rules,
	},
	// , {
	//     path: '/tutorial',
	//     component: Tutorial
	// }
	// , {
	//     path: '/rank',
	//     component: Rank
	// }
	{
		path: '/aboutus',
		component: Contact,
	},
	{
		path: '/privacy',
		component: Privacy,
	},
];
let navbar = [
	{
		path: '/',
		title: 'Home',
	},
	{
		path: '/rules',
		title: 'Rules',
	},
	{
		path: '/aboutus',
		title: 'Contact',
	},
];
function App() {
	return (
		<BrowserRouter>
			<MyLayout menu={navbar}>
				<Suspense
					fallback={
						<div style={{ height: '0' }}>
							<Row justify="center" style={{ paddingTop: '30vh' }}>
								<Col>
									<Spin indicator={antIcon} />
								</Col>
							</Row>
						</div>
					}
				>
					<Switch>
						{paths.map((item: any, index: any) => {
							return <Route exact={true} key={index} path={item.path} component={item.component} />;
						})}
						<Route exact={true} path="*" render={(routeProps) => <Redirect to="/" />} />
					</Switch>
				</Suspense>
			</MyLayout>
		</BrowserRouter>
	);
}

export default App;
